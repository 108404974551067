.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-bg);
    min-height: var(--proxim-app-height);
}

.content {
    flex-grow: 1;
    flex-shrink: 0;
    z-index: 0;
}

.menu {
    position: sticky;
    flex-shrink: 0;
    bottom: 0;
    z-index: 0;
}
