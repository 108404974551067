.headerMenu {
  padding-top: 16px;
  padding-bottom: 8px;
}

.header__title {
  font-size: 16px;
}

.content {
  padding: 80px 16px 50px 16px;

  &__title, &__text {
    margin-bottom: 24px;
  }
}
