.wrapperButtonStep {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-white);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 33px;
    padding-top: 16px;
    z-index: 4;
    &.gray {
        background-color: var(--color-gray-light);
    }
}