.wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-left: auto;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 3px;
  padding-right: 3px;
  &.right {
    background-color: var(--color-orange);
    color: var(--color-white);
    padding-left: 18px;
    padding-right: 18px;
  }
  &.cross {
    background-color: transparent;
    color: var(--color-gray-primary-600);
  }
}
