.name {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 12px;
}
.email {
    color: var(--color-orange-800);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0em;
    text-decoration: none;
}
.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}
.status {
    width: 13px;
    height: 13px;
    position: absolute;
    bottom: 3px;
    right: 3px;
    border: 2px solid var(--color-gray-light);
    background-color: var(--color-green);
    border-radius: 50%;
}
.avatarWrapper {
    position: relative;
}

.button {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
}