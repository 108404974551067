.toolItem {
    display: flex;
    gap:18px;   
    margin-bottom: 16px;
    padding-left: 15px;
}
.title {
    margin-bottom: 2px;
}
.description {
    font-size: 14px;
}