.uploader {
    position: relative;
    background: rgba(242, 106, 28, 0.1);
    border: 2.3px solid rgba(#f26a1c, 0.2);
    border-radius: 9px;
    padding: 5px 7px;
    height: 139px;
}

.uploaderIcon {
    width: 34px;
    height: 34px;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    &.active {
        transform: rotate(45deg);
        right: -6px;
        top: -8px;
        rect {
            fill: var(--color-white);
        }
        path {
            fill: var(--color-orange);
        }
    }
}

.uploadingSpiner {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: auto !important;
    height: auto !important;
}

.uploadingSpinerProgress {
    margin-top: 6px;
}

.uploadedImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.disabled {
    opacity: 15%;
}

.isUploading {
    pointer-events: none;
}
