.menu {
  display: flex;
  justify-content: center;
  height: var(--height-menu-app);
  background-color: var(--color-gray-bg);
  padding-top: 10px;
}

.list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 36px;
  height: 48px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-medium);
  transition: 0.6s;

  &.active {
    color: var(--color-orange);
  }
}

.count {
  width: 6px;
  height: 7px;
  position: absolute;
  right: 10px;
  top: 0;
  user-select: none;
}

.counter {
  overflow: hidden;
  text-align: center;
  min-width: 24px;
  position: absolute;
  left: 28px;
  top: 6px;
  padding: 2px 4px 0;
  color: rgba(248, 245, 243, 1);
  background: rgba(160, 166, 172, 1);
  border: 2px solid var(--color-white);
  border-radius: 32px;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  user-select: none;
  cursor: pointer;
}
