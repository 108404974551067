.premiumItem {
    position: relative;
    background-color: var(--color-white);
    padding: 16px;
    border-radius: 16px;
    display: block;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &.active {
        background-color: var(--color-black);
    }
}
.dateBlock {
    display: flex;
    align-items: center;
    gap: 10px;
}

.badge {
    background-color: var(--color-purple);
    font-size: 10px;
    line-height: 130%;
    padding: 4px 10px;
    border-radius: 26px;
}
.priceBlock {
    display: flex;
    align-items: center;
    gap: 5px;
}

.priceText {
}

.savingLabel {
    position: absolute;
    color: var(--color-white);
    right: -10px;
    top: -5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 130%;
    font-family: 'SFProText';
    width: 32px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
    background-color: var(--color-red);
    line-height: 130%;
}
