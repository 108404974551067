.descriptionWrapper {
  position: relative;
  transition: margin-top 0.3s ease-in, max-height 0s 0.3s ease;
  z-index: 10;
  max-height: 0;
  overflow: hidden;
  margin-top: 0;

  &.visibleDetails {
    max-height: 2000px;
    transition: margin-top 0.5s 0.2s ease-out, max-height 0s ease;
    margin-top: calc(var(--proxim-app-height) / -2);
    height: auto;
    overflow: visible;
  }
}

.infoWrapper {
  border-radius: 24px 24px 0 0;
  background-color: var(--color-gray-100);
  padding-top: 24px;;
}

.userInfo__header {
  display: flex;
  align-items: center;
  gap: 6px;
}

.city {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 26px;
  width: max-content;
  padding: 4px 8px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 1);
  border-radius: 27px;
}

.cardInfoText {
  margin-bottom: 12px;
}

.label {
  margin-bottom: 8px;
}

.text {
  margin-bottom: 18px;
  color: var(--color-gray-800);
}

.row {
  display: flex;
  align-items: center;
  gap: 13px;
  justify-content: space-between;
}

.rowItem {
  width: 100%;
}

.tagInfo {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 18px;
  width: 100%;
  border: none;
  background: rgb(220 220 220);
  border-radius: 24px;
  text-align: left;
  &.isIcon {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    color: var(--color-orange);
  }

  &__title {
    color: var(--05, rgba(99, 99, 102, 1));
    margin-bottom: 4px;
  }
}

.actionButtons {
  position: static;
  padding-bottom: 0;
}
