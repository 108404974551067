.wrapper {
  /* Add your styles here */
}
.formRadioGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 14px;
  background-color: var(--color-gray-bg);
  margin-bottom: 32px;
}
.label {
  margin-bottom: 16px;
}
.selectWrap {
  margin-bottom: 20px;
  border: 1px solid var(--color-gray-100);
  padding: 10px;
  border-radius: 14px;
}
.buttonReset {
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  padding: 0;
  position: absolute;
  top: 22px;
  left: 20px;
}
