.wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 74px;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -96.83%, rgba(0, 0, 0, 0.9) 100%);
    z-index: 101;
}

.container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 25px;
    padding-right: 25px;
}

.title {
    margin-bottom: 16px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.01em;
}
.text {
    margin-bottom: 20px;
    letter-spacing: 0em;
    line-height: 133%;
}
.button {
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    margin: 0 auto;
    display: block;
}

@keyframes hand {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-20deg);
    }

    50% {
        transform: rotate(12deg);
    }

    75% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.illustration {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 100px;
    position: relative;
    .hand {
        margin: 0 auto;
        animation: hand 2s ease-in-out;
        display: block;
    }
    .star-1,
    .star-2,
    .star-3 {
        color: var(--color-white);
        position: absolute;
        z-index: 1;
    }
    .star-1 {
        bottom: 30px;
        left: 15px;
        animation: sparkle 2s infinite linear;
    }
    .star-2 {
        bottom: 50px;
        left: 45px;
        animation: sparkle 3s infinite linear;
    }
    .star-3 {
        animation: sparkle 2.5s infinite linear;
        right: 10px;
        bottom: 30px;
    }
}


@keyframes sparkle {
    0% {
        opacity: 0;
        transform: scale(0.5) translateY(30px);
    }

    50% {
        opacity: 1;
        transform: scale(1) translateY(-20px); /* Переход вверх */
    }

    100% {
        opacity: 0;
        transform: scale(0.8) translateY(-60px); /* Исчезновение вверх */
    }
}
