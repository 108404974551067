/* typography.module.css */
.h1 {
    margin: 0;
    font-weight: 500;
    font-size: 44px;
    line-height: 120%;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
}

.h2 {
    margin: 0;
    font-size: 1.875rem; /* text-3xl */
    font-weight: bold;
}

.h3 {
    margin: 0;
    font-size: 28px; /* text-2xl */
    font-weight: 600; /* font-semibold */
}

.h4 {
    margin: 0;
    font-size: 1.25rem; /* text-xl */
    font-weight: 600; /* font-semibold */
}

.h5 {
    margin: 0;
    font-size: 1.125rem; /* text-lg */
    font-weight: 500; /* font-medium */
}

.h6 {
    margin: 0;
    font-size: 1rem; /* text-base */
    font-weight: 500; /* font-medium */
}

.p {
    margin: 0;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: 0.02em;
}

.span {
    display: inline;
    font-size: 13px;
    letter-spacing: -0.01em;
}

.label {
    display: block;
    font-weight: 500; /* font-medium */
    font-size: 17px;
}
// colors
.white {
    color: var(--color-white);
}

.orange {
    color: var(--color-orange);
}

.black {
    color: var(--color-black);
}
.gray {
    color: var(--color-gray-primary-600);
}
.grayLight {
    color: var( --color-gray-light-text);
}

.grayLabel {
    color: var(--color-gray-03);
}

.brown {
    color: var(--color-brown);
}

.uppercase {
    text-transform: uppercase;
}
.center {
    text-align: center;
}
// font-weight
.normal {
    font-weight: 400;
}
.medium {
    font-weight: 500;
}
.semibold {
    font-weight: 600;
}
.bold {
    font-weight: 700;
}

.error {
    color: var(--color-error);
}

.SFProDisplay {
    font-family: 'SFProDisplay';
}
.SFProText {
    font-family: 'SFProText';
}