.wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.details {
    display: flex;
    flex-direction: column;
}

.status {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border: 2px solid var(--color-gray-light);
    background-color: var(--color-green);
    border-radius: 50%;
}

.avatarWrap {
    position: relative;
}