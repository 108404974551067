.wrapper {
    /* Add your styles here */
    // width: 100%;
    // min-height: 100%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 286px;
}
.icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-medium);
    border-radius: 100%;
    background-color: var(--color-gray-100);
    margin: 0 auto;
    margin-bottom: 10px;
}
.title {
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
}
.text {
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.01em;
}
