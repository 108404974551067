.pagination {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
}
.paginationList {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    padding-left: 40px;
    padding-right: 40px;
    margin: 0;

}

.paginationItem {
    font-size: 0;
    height: 3px;
    width: 100%;
    background: var(--color-gray-light);
    border-radius: 2px;
    &.active {
        background: var(--color-orange);
    }
}