.form {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    flex-grow: 1;
}

.formContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    flex-grow: 1;
    flex-shrink: 0;
}

.formLabel {
    margin-bottom: 16px;
}

.formRadioGroup {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.formCardsBlock {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    margin-bottom: 16px;
    max-height: 136px;
}

.titleForm {
    margin-bottom: 20px;
    font-size: 17px;
}