@mixin font($fname, $fstyle, $fweight, $furlwoff2, $furlwoff) {
    @font-face {
        font-family: $fname;
        font-style: $fstyle;
        font-weight: $fweight;
        src:
            url($furlwoff2) format('woff2'),
            url($furlwoff) format('woff');
    }
}
// SFProDisplay
@include font(
    'SFProDisplay',
    normal,
    900,
    '/assets/fonts/SFProDisplay/SFProDisplay-Heavy.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Heavy.woff'
);

@include font(
    'SFProDisplay',
    normal,
    800,
    '/assets/fonts/SFProDisplay/SFProDisplay-Black.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Regular.woff'
);

@include font(
    'SFProDisplay',
    normal,
    700,
    '/assets/fonts/SFProDisplay/SFProDisplay-Bold.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Bold.woff'
);

@include font(
    'SFProDisplay',
    normal,
    600,
    '/assets/fonts/SFProDisplay/SFProDisplay-Semibold.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Semibold.woff'
);
@include font(
    'SFProDisplay',
    normal,
    500,
    '/assets/fonts/SFProDisplay/SFProDisplay-Medium.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Medium.woff'
);

@include font(
    'SFProDisplay',
    normal,
    400,
    '/assets/fonts/SFProDisplay/SFProDisplay-Regular.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Regular.woff'
);
@include font(
    'SFProDisplay',
    normal,
    300,
    '/assets/fonts/SFProDisplay/SFProDisplay-Light.woff2',
    '/assets/fonts/SFProDisplay/SFProDisplay-Light.woff'
);
// SFProText

@include font(
    'SFProText',
    normal,
    700,
    '/assets/fonts/SFProText/SFProText-Bold.woff2',
    '/assets/fonts/SFProText/SFProText-Bold.woff'
);
@include font(
    'SFProText',
    normal,
    600,
    '/assets/fonts/SFProText/SFProText-Semibold.woff2',
    '/assets/fonts/SFProText/SFProText-Semibold.woff'
);
@include font(
    'SFProText',
    normal,
    500,
    '/assets/fonts/SFProText/SFProText-Medium.woff2',
    '/assets/fonts/SFProText/SFProText-Medium.woff'
);
@include font(
    'SFProText',
    normal,
    400,
    '/assets/fonts/SFProText/SFProText-Regular.woff2',
    '/assets/fonts/SFProText/SFProText-Regular.woff'
);
@include font(
    'SFProText',
    normal,
    300,
    '/assets/fonts/SFProText/SFProText-Light.woff2',
    '/assets/fonts/SFProText/SFProText-Light.woff'
);
