.overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; // Модалка будет внизу
    z-index: 1000;
    animation: fadeIn 0.3s ease-in;
    &.closing {
        animation: fadeOut 0.3s ease-out forwards;
    }
}

.confirm {
    background: white;
    padding: 0;
    border-radius: 14px;
    width: calc(100% - 120px);
    // max-width: 500px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease; // Анимация
    position: relative;
    transform: scale(1);
    opacity: 1;
    overflow: hidden;
}
.confirm.closing {
    animation: slideDown 0.3s ease forwards;
}


.content {
    padding: 10px 0;
}

.info {
    padding: 20px;
}

@keyframes slideUp {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {

        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.9);
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        background: rgba(0, 0, 0, 0);
    }
    to {
        background: rgba(0, 0, 0, 0.5);
    }
}
@keyframes fadeOut {
    from {
        background: rgba(0, 0, 0, 0.5);
    }
    to {
        background: rgba(0, 0, 0, 0);
    }
}

.title {
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    margin-bottom: 14px;
    margin-bottom: 6px;
}
.text {
    font-size: 14px;
    line-height: 129%;
    letter-spacing: -0.01em;
}
.button {
    padding: 11px 5px;
    flex-basis: 50%;
    border-top: 0.33px solid var(--color-gray-100);
    border-radius: 0;
    font-family: 'SFProText';

    font-size: 17px;
    &.closeButton {
        border-right: 0.33px solid var(--color-gray-100);
        font-weight: 400;
    }
}

.buttonsWrapper {
    display: flex;
}
