.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--proxim-app-height);
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    z-index: 1000;
    overflow: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;


    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;

        animation: {
            name: fadeIn;
            duration: 0.3s;
            iming-function: linear;
            fill-mode: forwards;
        }
    }
    
    &.closing::before {
        animation: {
            name: fadeOut;
            duration: 0.3s;
            iming-function: ease-out;
            fill-mode: forwards;
        }
    }
}

.modal {
    margin-top: auto;
    background: white;
    padding: 24px 20px;
    border-radius: 10px 10px 0 0; // Скругление только сверху
    width: 100%;
    // max-width: 500px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease-out; // Анимация
    position: relative;
    &::before {
        content: '';
        background-color: var(--color-gray-300);
        width: 36px;
        position: absolute;
        left: 50%;
        top: 14px;
        transform: translateX(-50%);
        height: 4px;
        border-radius: 4px;
        display: none;
    }
    &.dekorTop {
        &::before {
            display: block;
        }
    }
}
.modal.closing {
    animation: slideDown 0.3s ease-out forwards;
}

.closeButton {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    padding: 0;
}

.content {
    height: calc(100% - 20px);
    padding: 10px 0;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.title {
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    margin-bottom: 14px;
}
