.back {
    color: var(--color-brown);
}

.fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    background: transparent;

    &.gray {
        background-color: var(--color-gray-bg);
    }

    &.white {
        background-color: var(--color-white);
    }
}

.nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;
    width: 100%;
}

.title {
    font-weight: 500;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
}

.childContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.appendContainer,
.prependContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: max-content;
}

.appendContainer {
    right: 0;
}

.prependContainer {
    left: 0;
}