.form {
    display: flex;
    flex-direction: column;
    // margin-top: 19px;
    justify-content: space-between;
    flex-grow: 1;
}
.formContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    flex-grow: 1;
    flex-shrink: 0;
}
.formButton {
    align-self: flex-end;
}

.formLabel {
    margin-bottom: 16px;
    font-size: 17px;
}

.formRadioGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding-right: 32px;
}

.required {
    position: relative;
    display: inline-block;
    max-width: max-content;
    &::after {
        content: '*';
        color: var(--color-orange);
        position: absolute;
        right: -10px;
        top: -2px;
    }
}

.titleForm {
    margin-bottom: 20px;
    font-size: 17px;
}