.profileHeaderMenu {
  padding-top: 16px;
  padding-bottom: 8px;
}

.container {
  height: 100dvh;
  padding-bottom: calc(var(--height-menu-app) + 35px);
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;

  &.visibleDetails {
    overflow: auto;
  }
}

.card {
  position: relative;
  height: calc(var(--proxim-app-height) - var(--height-menu-app));
  overflow: hidden;
  width: 100%;
}

.userInfo {
  padding-top: 24px;
  border-radius: 24px 24px 0 0;
  background-color: var(--color-gray-100);
  position: relative;
  transition: all 0.3s ease;
  z-index: 10;

  &__title {
    margin-bottom: 24px;
  }

  &.visibleDetails {
    margin-top: -100px;
  }
}

.userInfo__header {
  display: flex;
  align-items: center;
  gap: 6px;
}

.city {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 26px;
  width: max-content;
  padding: 4px 8px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 1);
  border-radius: 27px;
}

.label {
  margin-bottom: 12px;
}

.text {
  margin-bottom: 24px;
}

.cardInfo {
  position: absolute;
  bottom: 115px;
  left: 0;
  width: 100%;
  padding: 0 16px;
  transition: all 0.3s ease;

  &.visibleDetails {
    transform: translateY(100%);
    opacity: 0;
  }
}

.cardInfo {
  position: absolute;
  bottom: 115px;
  left: 0;
  width: 100%;
  padding: 0 16px;
  transition: all 0.3s ease;

  &.visibleDetails {
    transform: translateY(100%);
    opacity: 0;
  }
}

.cardInfoText {
  margin-bottom: 12px;
}

.descriptionWrapper {
  padding-top: calc(var(--proxim-app-height) - 44px);
  padding-bottom: 30px;
  display: none;

  &.visibleDetails {
    display: block;
  }
}

.descriptionIntro {
  max-width: 289px;
  height: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.arrowTop {
  position: absolute;
  bottom: 136px;
  right: 25px;
  width: 32px;
  height: 32px;
  z-index: 100;

  svg {
    color: var(--color-black);
  }

  &.visibleDetails {
    transform: rotate(180deg);
  }
}

.tagInfo {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 18px;
  width: 100%;
  border: none;
  background: rgb(220 220 220);
  border-radius: 24px;
  text-align: left;
  &.isIcon {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    color: var(--color-orange);
  }

  &__title {
    color: var(--05, rgba(99, 99, 102, 1));
    margin-bottom: 4px;
  }
}