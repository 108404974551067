.react-select {
    .react-select__control {
        border: none;
        border-color: transparent;
        min-height: auto;
        box-shadow: none;
    }
    .react-select__control--is-focused {
        border: none;
    }
    .react-select__indicators {
        // display: none;
    }
    .react-select__value-container {
        padding-left: 0;
    }
    .react-select__placeholder {
        font-weight: 400;
        font-size: 16px;
        color: var(--color-gray-placeholder);
    }
    .select-empty {
        padding: 8px;
        text-align: center;
    }
}
