.wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.avatar {
  max-width: 64px;
  flex-shrink: 0;
  position: relative;
}

.blurImageWrapper {
  width: 64px;
  height: 64px;
}

.blurImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;

  .image {
    filter: blur(2px);
  }
  img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    display: block;
  }
}

.heart {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: #fff;
}

.status {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 3px;
  right: 3px;
  border: 2px solid var(--color-gray-light);
  background-color: var(--color-green);
  border-radius: 50%;
}

.imageAvatar {
  max-width: 64px;
  overflow: hidden;
  width: 100%;
  img {
    display: block;
  }
}

.info {
  margin-bottom: 6px;
}

.name {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.4px;
}

.time {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
}

.buttonWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 3px;
  padding-right: 3px;
  &.right {
    background-color: var(--color-orange);
    color: var(--color-white)
  }
}

