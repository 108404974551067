.modal {
  min-height: 84%;
}

.textareaWrapper {
  background: #F0F0F0;
  border-radius: 12px;
  padding: 16px;
}

.textarea {
  background: #F0F0F0;
}

.buttonCancel {
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  padding: 0;
  position: absolute;
  top: 22px;
  left: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}