.coupleItem {
    border-radius: 8px;
    height: max-content;
    text-decoration: none;
}
.avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    &.ellipse {
        &::before {
            content: '';
            position: absolute;
            width: 62px;
            height: 62px;
            display: block;
            background-color: transparent;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 2px solid var(--color-blue);
        }
    }
}

.imageAvatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
        display: block;
    }
}

.info {
    font-size: 10px;
}