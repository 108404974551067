.list {
    display: flex;
    gap: 12px;
    padding-right: 16px;
    padding-left: 16px;
    overflow-x: scroll;
    scrollbar-width: none; /* Для Firefox */
}

.list::-webkit-scrollbar {
    display: none; /* Для WebKit браузеров */
}

.simplebar-placeholder {
    display: none;
}
.label {
    margin-bottom: 16px;
}