.button {
    padding: 0;
    background: transparent;
}
.rowButtons {
    display: flex;
    gap: 9px;
    margin-left: auto;
}

.header {
    background-color: var(--color-gray-300);
}

.menuDropdown {
    padding: 0;
    color: var(--color-brown);
}

.chatHeaderMenu {
    padding-top: 16px;
    padding-bottom: 8px;
}

.headerChat {
    flex-grow: 1;
    margin-left: 40px;
}
