.HeaderPicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 20px 8px;
}

.CurrentMonth {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.03em;
    font-family: 'SFProText';
    margin: 0;
}

.arrows {
    display: flex;
    align-items: center;
    gap: 24px;

    &.hide {
        visibility: hidden;
    }
}

.PickerArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    transition: 0.3s;

    &.active {
        rotate: 90deg;
    }
}
