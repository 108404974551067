.wrapper {
    /* Add your styles here */
    padding-bottom: var(--height-menu-app);
    padding-top: var(--height-header);
}
.uploaderBlok {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.formLabel {
    margin-bottom: 16px;
}

.label {
    font-size: 17px;
    letter-spacing: -0.02em;
}

.formCardsBlock {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    margin-bottom: 16px;
    max-height: 136px;
}

.formRadioGroup {
    display: flex;
    justify-content: space-between;
}

.savedButton {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    padding: 0;
    padding-top: 1px;
    background-color: transparent;
}
