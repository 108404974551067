.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.button {
        position: relative;
        .radio {
            background-image: none;
            width: 100%;
            height: 100%;
            background-color: var(--color-gray-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            border-radius: 14px;
        }
        .radio::after {
            background-image: none;
        }
        input[type='radio']:checked + .radio::after {
            background-color: var(--color-orange);
            width: 100%;
            border-radius: 14px;
            height: 100%;
        }
        .label {
            position: relative;
            margin: 0 auto;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 13px;
            line-height: 138%;
        }
        input[type='radio']:checked + .radio + .label {
            color: var(--color-white);
        }
    }
}

.radio {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 6px;
    transition: background-color 0.3s ease;
    background-image: url('../../assets/images/radio.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.radio::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url('../../assets/images/radio-check.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

input[type='radio']:checked + .radio::after {
    opacity: 1;
}

.label {
    font-size: 16px;
    color: var(--color-gray-03);
}

input[type='radio'] {
    display: none;
}
