@import 'normalize.css';
@import 'swiper/css';
@import './fonts.scss';
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-toastify/dist/ReactToastify.css';
@import './effect-tinder.scss';

// Цвета по критериям https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
:root {
    --color-orange: #f26a1c;
    --color-white: #ffffff;
    --color-white-01: #f5f5f5;

    --color-black: #000000;
    --color-black-01: #1c1c1e;

    --color-gray-02: #48484a;
    --color-gray-03: #636366;

    --color-gray-bg: #ecebe9;
    --color-gray-light: #ecebe9;

    --color-gray-dark: #707579;
    --color-gray-medium: #b0b5ba;

    --color-gray-100: #ecebe9;
    --color-gray-400: #c9c8c8;
    --color-gray-300: #d9d9d9;
    --color-gray-placeholder: #c7c7cc;
    --color-gray-range: #efeff4;
    --color-gray-primary-600: #707579;
    --color-gray-600: #868686;
    --color-gray-800: #4f4f4f;

    --color-orange-100: #ffe0b4;
    --color-orange-300: #f99861;
    --color-orange-800: #f26a1c;

    --color-gray-light-text: #9f9ea1;

    --color-brown: #421421;
    --color-brown-20: rgba(242, 106, 28, 0.2);
    --color-brown-10: rgba(242, 106, 28, 0.1);

    --color-red: #e53935;
    --color-error: #e53935;

    --color-green: #52ff00;

    --color-blue: #777bdf;

    --color-purple: #a43bf7;

    --color-beige-100: #f5e8e0;
    --color-beige-300: #f4bb9a;

    --height-header: 42px;

    --height-menu-app: 86px;

    --proxim-app-height: 100dvh;
    --proxim-app-card-brd-radius: 16px;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

html {
    // to remove a defect when tapping on mobile devices
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    // height: var(--proxim-app-height);
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    background: var(--color-gray-bg);
    overflow: hidden;

    &.with-modal {
        background-color: #fff;
    }
}

html.unknown.web-ios {
    --proxim-app-height: 100dvh;
}

body {
    margin: 0;
    min-width: 320px;
    font-family: 'SFProText', 'SFProDisplay', Arial, Helvetica, sans-serif;
    height: var(--proxim-app-height);
    overflow-y: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
}

textarea,
input,
button,
select {
    font-family: inherit;
    font-size: inherit;
}

img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

button {
    border: unset;
    outline: unset;
    background: unset;
    padding: unset;
}

.toastContainer {
    bottom: var(--height-menu-app);
}

.swiper {
    z-index: 3;
}
