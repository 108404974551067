.badgeDay {
    font-size: 10px;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 3px 6px;
    margin-top: 12px;
    margin-bottom: 22px;
}

.badgeText {
    font-size: 10px;
    color: #999999;
    line-height: 1;
}