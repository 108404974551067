.settingsItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 4px;
    color: var(--color-brown);
    text-decoration: none;
    font-size: 14px;
}

.text {
    font-size: 16px;
    line-height: 131%;
    letter-spacing: -0.02em;
}