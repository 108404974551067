.settingsButton {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: auto;
}
.container {
    // position: absolute;
    display: flex;
    flex-direction: column;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    padding-bottom: calc(var(--height-menu-app) + 20px);
    padding-top: 20px;
}

.buttonBadge {
    padding: 10px 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    font-family: 'SFProText';
    margin: 0 auto;
}
