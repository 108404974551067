.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(var(--proxim-app-height) - 78px);
    overflow-y: auto;
}

.wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    overflow: hidden;
    max-height: var(--proxim-app-height);
}
