.wrapper {
  position: relative;
}

.preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #e0e0e0 25%, #c0c0c0 50%, #e0e0e0 75%);
  background-size: 400% 400%;
  animation: shimmer 1.5s infinite linear;
}