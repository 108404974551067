.container {
    margin-top: 24px;
    padding: 16px;
    flex: 1;
    border-radius: 24px 24px 0 0;
    background-color: var(--color-white);
}

.label {
    margin-bottom: 14px;
}

.messagesList {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
