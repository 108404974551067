.title {
  font-size: 14px;
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 5px;
}

.text {
  font-weight: 400;
  font-size: 13px;
  line-height: 138%;
  margin-bottom: 16px;
}

.container {
  background-color: var(--color-gray-100);
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  padding-top: 30px;
}

.button {
  width: max-content;
  margin: 0 auto 32px;
  padding: 10px 16px;

  span {
    padding-right: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.6px;
  }
}

.heart {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.dekorHeart {
  // position: absolute;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 10px;
  // top: 56px;
  // left: 50%;
  // transform: translateX(-50%);
  background-image: url('/assets/images/subscription/badge-heart.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.noLike {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 76%;
  margin: 0 auto;

  &__dekor {
    margin: 0 auto 10px;
    width: 46px;
    height: 46px;
    background-image: url('/assets/images/subscription/badge-heart-grey.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.4099999964237213px;
    text-align: center;
    margin-bottom: 6px;
  }

  &__titleText {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__button {
    margin: 0 auto;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.4px;
    width: max-content;
  }
}
