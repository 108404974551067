.card {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    padding: 15px;
    &.active {
        padding: 12px;
        border: 3px solid var(--color-orange);
    }
}
.cardTitle {
    font-weight: 600;
    font-size: 11px;
    line-height: 1;
    letter-spacing: -0.04em;
}

.cardIcon {
    width: 52px;
    height: 52px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &.love {
        background-image: url(../../assets/images/love-icon.png);
    }
    &.fun {
        background-image: url(../../assets/images/fun-icon.png);
    }
    &.not_specified {
        background-image: url(../../assets/images/not_specified-icon.png);
    }
    margin-bottom: 22px;
}