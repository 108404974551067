.wrapper {
  height: calc(var(--proxim-app-height) - var(--height-menu-app));

  &.visibleDetails {
    .image img {
      // height: auto;
      // object-fit: contain;
    }
  }
}

.pagination {
  z-index: 1;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: auto;

  img {
    object-fit: cover;
    height: 100%;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none;
  }

  .gradientLeft,
  .gradientRight {
    opacity: 0;
  }

  &.gradientLeft::after {
    background: linear-gradient(180deg, rgba(255, 0, 107, 0) 0%, rgba(255, 0, 107, 1) 100%);
  }

  &.gradientLeft::before {
    content: '';
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
    display: block;
    width: 80px;
    height: 80px;
    background: url("../../assets/icons/close-white.svg") no-repeat center;
  }

  &.gradientRight::after {
    background: linear-gradient(180deg, rgba(242, 106, 28, 0) 0%, rgba(242, 106, 28, 0.7) 100%);
  }

  &.gradientRight::before {
    content: '';
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
    display: block;
    width: 80px;
    height: 80px;
    background: url("../../assets/icons/like-white.svg") no-repeat center;
  }
}

.matchedImage {
  &::after {
    background: linear-gradient(180deg, rgba(242, 106, 28, 0) 0%, rgba(242, 106, 28, 0.7) 100%);
  }
}

.galleryImageHidden {
  display: none;
}

.avatarIcon {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  border: 8px solid currentColor
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
