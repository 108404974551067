:root {
  --swiper-tinder-no-color: red;
  --swiper-tinder-yes-color: green;
  --swiper-tinder-label-text-color: #fff;
  --swiper-tinder-label-font-size: 32px;
  --swiper-tinder-button-size: 56px;
  --swiper-tinder-button-icon-size: 32px;
}
.swiper-slide {
  width: 100% !important;
}

.swiper-tinder {
  box-sizing: border-box;
  .swiper-slide {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.swiper-tinder-label {
  position: absolute;
  font-size: var(--swiper-tinder-label-font-size);
  font-weight: bold;
  padding: 4px 12px;
  text-transform: uppercase;
  border-radius: 4px;
  opacity: 0;
  color: var(--swiper-tinder-label-text-color);
  z-index: 4;
}
.swiper-tinder-label-yes {
  left: 3%;
  top: 5%;
  transform: rotate(-20deg);
  background-color: var(--swiper-tinder-yes-color);
  transform-origin: right top;
}

.swiper-tinder-label-no {
  right: 3%;
  top: 5%;
  transform: rotate(20deg);
  background-color: var(--swiper-tinder-no-color);
  transform-origin: left top;
}
.swiper-tinder-button {
  position: absolute;
  cursor: pointer;
  bottom: 32px;
  width: var(--swiper-tinder-button-size);
  height: var(--swiper-tinder-button-size);
  border: 1px solid;
  border-radius: 999px;
  background: transparent;
  appearance: none;
  outline: none;
  box-shadow: none;
  z-index: 100;
  transition-duration: 100ms;
  font-size: var(--swiper-tinder-button-icon-size) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  svg {
    width: var(--swiper-tinder-button-icon-size);
    height: var(--swiper-tinder-button-icon-size);
  }
  &-hidden {
    opacity: 0;
    pointer-events: none;
  }
  &-no {
    border-color: var(--swiper-tinder-no-color);
    left: calc(33% - 28px);
    svg {
      fill: var(--swiper-tinder-no-color);
    }
    &.swiper-tinder-button-active,
    &:active {
      background: var(--swiper-tinder-no-color);
      svg {
        fill: #fff;
      }
    }
  }
  &-yes {
    border-color: var(--swiper-tinder-yes-color);
    left: calc(66% - 28px);
    svg {
      fill: var(--swiper-tinder-yes-color);
    }
    &.swiper-tinder-button-active,
    &:active {
      background: var(--swiper-tinder-yes-color);
      svg {
        fill: #fff;
      }
    }
  }
}

.gradientNo,
.gradientYes {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  border-radius: var(--proxim-app-card-brd-radius);

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 80px;
    height: 80px;
  }
}

.gradientNo {
  background: linear-gradient(180deg, rgba(255, 0, 107, 0) 0%, rgba(255, 0, 107, 1) 100%) no-repeat center bottom / 100% 50%;

  &::before {
    background: url("../assets/icons/close-white.svg") no-repeat center;
  }
}

.gradientYes {
  background: linear-gradient(180deg, rgba(242, 106, 28, 0) 0%, rgba(242, 106, 28, 0.7) 100%) no-repeat center bottom / 100% 50%;

  &::before {
    background: url("../assets/icons/like-white.svg") no-repeat center;
  }
}
