.form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.formContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    flex-grow: 1;
    flex-shrink: 0;
}

.uploaderBlok {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap:10px ;
    margin-bottom: 20px;
}

.formButton {
    align-self: flex-end;
}
.formLabel {
    margin: 0 auto;
    margin-bottom: 29px;
    max-width: 282px;
    
}
.titleForm {
    font-size: 17px;
    margin-bottom: 20px;
}