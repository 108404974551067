.tag {
    display: inline-block;
    border-radius: 9px;
    border: 2px solid #e5e5ea;
    background: rgba(242, 242, 247, 0.6);
    font-size: 14px;
    line-height: 120%;
    letter-spacing: .06rem;
    color: var(--color-white);
    font-weight: 400;
    padding: 8px 16px;
    text-align: center;
    font-size: 16px;
    &.isIcon {
        display: inline-flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
    }
}
