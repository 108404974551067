.wrapper {
    position: relative;
}

.box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 2px solid var(--color-orange);
}

.icon {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-beige-300);
}