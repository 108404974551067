.section {
    padding-bottom: var(--height-menu-app);
}

.wrapper {
    background-image: url('/assets/images/subscription/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: var(--color-orange);
    overflow: hidden;
    width: 100%;
    border-radius: 0 0 18px 18px;
}

.dekor {
    overflow: hidden;
    width: 100%;
    position: absolute;
    height: 400px;
    margin: 0 auto;
}

.dekorLight {
    position: absolute;
    width: 390px;
    height: 390px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('/assets/images/subscription/light.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: animationLight 60s linear infinite;
}

@keyframes animationLight {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }
    100% {
        transform: translateX(-50%) rotate(360deg);
    }
}
.dekorHeart {
    position: absolute;
    width: 248px;
    height: 189px;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('/assets/images/subscription/heart.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.info {
    position: relative;
    margin-top: 247px;
    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}
.title {
    font-size: 22px;
}
.description {
    font-size: 16px;
}
.badgeContainer {
    margin-bottom: 104px;
}

.badgeText {
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
}
.button {
    // position: fixed;
    // bottom: 20px;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: calc(var(--height-menu-app) + 20px); 
    // z-index: 3;
    // width: calc(100% - 32px);
    margin-top: 33px;
}
.badgeContainer {
    overflow: visible;
}