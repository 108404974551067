.row {
    display: flex;
    &.rowAccepted {
        justify-content: flex-start;
        .messageText {
            background-color: #E9E9EB;
        }
        margin-bottom: 16px;
    }
    &.rowSent {
        justify-content: flex-end;
        .messageText {
            background-color: #FDE7DA;
        }
        .dekorIcon {
            left: auto;
            transform: scale(-1, 1);
            right: -5px;
            path {
                fill: #FDE7DA;
            }
        }
    }
    margin-bottom: 6px;

}

.message {
    flex-shrink: 0;
    width: calc(100% - 94px);
}

.messageText {
    border-radius: 18px;
    padding: 12px;
    display: block;
    width: 100%;
    margin-bottom: 4px;
    position: relative;
    font-size: 12px;
    letter-spacing: 0.02rem;
    line-height: auto;

    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    .dekorIcon {
        position: absolute;
        bottom: 0px;
        left: -5px;
        display: block;
    }
}
.messageTime {
    font-size: 11px;
    text-align: right;
    display: block;
    color: rgba(60, 60, 67, 0.6);
    b {
        font-weight: 500;
    }
}

.messageIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -8px;
    display: block;
}

.skeleton {

    .messageText {
        background-color: #E9E9EB !important;
    }
    .dekorIcon {
        path {
            fill: #E9E9EB !important;
        }
    }
}
