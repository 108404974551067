.progress {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.progressSvg {
  transform: rotate(-90deg);
}

.progressCircle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(0.25turn);
  transform-origin: 50% 50%;
}

.progressText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
