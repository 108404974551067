.navigation {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    z-index: 10;
    pointer-events: auto;
}
.navigationItem {
    flex: 1;
    font-size: 0;
}