.wrapper {
    /* Add your styles here */
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--color-white-01);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
}
.icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-medium);
    border-radius: 100%;
    background-color: var(--color-white);
    margin: 0 auto;
    margin-bottom: 10px;
}
.title {
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    margin-bottom: 5px;
}
.text {
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
}

.button {
    padding: 7px 12px;
    font-size: 13px;
    line-height: 138%;
}
