.avatar {
    width: 60px;
    height: 60px;
    overflow: hidden;
    img {
        display: block;
        object-fit: cover;
        height: 100%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    &.default {
        background-color: var(--color-gray-dark);
    }
    color: var(--color-white);
}
