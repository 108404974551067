.wrapper {
  padding: 16px;

  .pickerItem {
    transition: all .1s ease;
    font-size: 17px;
    font-family: 'SFProText';
  }

  .pickerItemSelected {
    font-weight: 600;
    color: var(--color-orange);
  }
}

