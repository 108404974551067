.wrapperStep {
    display: flex;
    align-items: center;
    justify-content: center;

    b {
        color: var(--color-orange);
        font-weight: 600;
    }
}

.text {
    font-size: 13px;
}