.mainWrapper {
  display: block;
}

.container {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-100);
}

.card {
  position: relative;
  height: calc(var(--proxim-app-height) - var(--height-menu-app));
  overflow: hidden;
  width: 100%;
  border-radius: var(--proxim-app-card-brd-radius);
}

.wrapper {
  position: relative;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.cardInfo {
  position: absolute;
  bottom: 135px;
  left: 0;
  width: 100%;
  padding: 0 16px;
  transition: all 0.3s ease;

  &.visibleDetails {
    transform: translateY(100%);
    opacity: 0;
  }
}

.cardInfoText {
  margin-bottom: 12px;
}

.descriptionWrapper {
  padding-top: calc(var(--proxim-app-height) - 44px);
  padding-bottom: 30px;
  display: none;

  &.visibleDetails {
    display: block;
  }
}

.label {
  margin-bottom: 8px;
}

.row {
  display: flex;
  align-items: center;
  gap: 13px;
  justify-content: space-between;
}

.rowItem {
  width: 100%;
}

.gridTags {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px 15px;
}

.tagInfo {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 18px;
  width: 100%;

  &.isIcon {
    width: 100%;
    justify-content: start;
    display: flex;
    color: var(--color-gray-800);
  }
}

.tinderCardWrapper {
  width: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 3 !important;
  background-color: var(--color-gray-bg);
}

.left {
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  position: absolute;
}

.right {
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  position: absolute;
}

.text {
  margin-bottom: 18px;
  color: var(--color-gray-800);
}

.city {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  margin-bottom: 26px;
}

.arrowTop {
  display: flex;
  position: absolute;
  bottom: 135px;
  right: 25px;
  width: 32px;
  z-index: 100;
  transition: all 0.3s ease-in;

  svg {
    color: var(--color-black);
  }

  &.visibleDetails {
    transition: all 0.5s 0.2s ease-out;
    transform: rotate(180deg);
    bottom: calc(var(--proxim-app-height) / 2 + 15px);
  }
}

.disableSwiper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 9;
}

.descriptionIntro {
  max-width: 289px;
  max-height: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  overflow: hidden;
}

.tagIconNode {
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &.love {
    background-image: url(../../assets/images/love-icon.png);
  }

  &.fun {
    background-image: url(../../assets/images/fun-icon.png);
  }

  &.not_specified {
    background-image: url(../../assets/images/not_specified-icon.png);
  }
}

.filterButton {
  position: absolute;
  z-index: 100;
  top: 16px;
  right: 16px;
  padding: 0;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(123, 123, 123, 0.64);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }
}

.loadMoreCard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #b3b3b3;
  height: calc(var(--proxim-app-height) - 74px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 2;
  overflow: hidden;

  background-image: linear-gradient(
                  90deg,
                  rgba(150, 150, 150, 0.3) 25%,
                  rgba(150, 150, 150, 0.5) 50%,
                  rgba(150, 150, 150, 0.3) 75%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

