.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url('/assets/images/last-card.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(var(--proxim-app-height) - 74px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 1;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.02em;
    margin-bottom: 5px;
}
.text {
    max-width: 280px;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.emoji {
    width: 56px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.button {
    margin: 0 auto;
    padding: 7px 12px;
    margin-top: 16px;
    font-size: 13px;
}
