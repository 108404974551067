.profile {
    background-color: var(--color-gray-100);
    min-height: calc(var(--proxim-app-height) - var(--height-menu-app));
}
.button {
    padding: 0;
    background: transparent;
    path {
        fill: var(--color-gray-primary-600);
    }
}