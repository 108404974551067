/* Input.module.css */

.container {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 12px;
}

.label {
    margin-bottom: 16px;
    color: #333;
    &.error {
        color: var(--color-error);
    }
}

.input {
    font-size: 16px;
    border-radius: 5px;
    border: none;
    transition: border-color 0.3s ease;
    &::placeholder {
        font-weight: 400;
        font-size: 16px;
        color: var(--color-gray-placeholder);
    }
}

.input:focus {
    border-color: #0070f3;
    outline: none;
}

.input.error {
    border-color: red;
}

.errorText {
    font-size: 12px;
    color: var(--color-error);
    margin-top: 0.5rem;
}

.input.success {
    border-color: green;
}

.input.disabled {
    background-color: #f0f0f0;
    border-color: #ccc;
    cursor: not-allowed;
}

.required {
    position: relative;
    display: inline-block;
    max-width: max-content;
    &::after {
        content: '*';
        color: var(--color-orange);
        position: absolute;
        right: -10px;
        top: -2px;
    }
}