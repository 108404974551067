
.container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 20px;
}

.label {
    margin-bottom: 16px;
    color: var(--color-black);
    &.error {
        color: var(--color-error);
    }
}

.textarea {
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    // resize: vertical;
    transition: border-color 0.3s ease;
    &::placeholder {
        color: var(--color-gray-placeholder);
    }
}

.textarea:focus {
    outline: none;
    border-color: var(--color-gray-03);
}

.characterCount {
    font-size: 13px;
    color: var(--color-gray-placeholder);
    margin-top: 0.5rem;
    text-align: right;
    position: absolute;
    right: 5px;
    bottom: 0px;
}

.characterCount.error {
    color: var(--color-error);
}

.textarea.error {
    border-color: var(--color-error);
}

.required {
    position: relative;
    display: inline-block;
    max-width: max-content;
    &::after {
        content: '*';
        color: var(--color-orange);
        position: absolute;
        right: -10px;
        top: -2px;
    }
}
