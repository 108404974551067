.button {
    padding: 0;
    padding-right: 14px;
    align-self: flex-end;
    padding-left: 14px;
    display: flex;
    align-items: center;
    background-color: #FAFAFA;
}
.formMessage {
    border-radius: 10px;
    position: relative;
    background: #FAFAFA;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 14px;
    padding-right: 0;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 100%;
        height: 4px;
        background-color: var(--color-white);
        left: 0;
    }
}
.wrapper {
    position: sticky;
    bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    background-color: var(--color-white);
    margin-top: auto;
}
.textarea {
    width: 100%;
    border: none;
    align-self: flex-end;
    background-color: #FAFAFA;

    resize: none;
    &:focus {
        outline: none;
    }
    &::placeholder {
        font-size: 14px;
        color: #999999;
    }
}
