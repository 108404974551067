.wrapper {
    /* Add your styles here */
    padding: 16px;
    background-image: url('/assets/images/bg-badge.svg');
    background-color: var(--color-white);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    display: flex;
    gap: 12px;
    &.column {
        padding: 24px;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .description {
            margin-bottom: 16px;
        }
        .title {
            margin-bottom: 5px;
        }
    }
  
}
.dekor {
    width: 48px;
    flex-shrink: 0;
    img {
        display: block;
    }
}
.title {
    font-size: 16px;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 131%;
    letter-spacing: -0.02em;
}
.description {
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.01em;
}
