.wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    padding-bottom: 30px;
    z-index: 3;
    overflow: hidden;
}
.button {
    width: 78px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 0;
    border: none;
    border-radius: 50%;
    transition: box-shadow .2s ease, opacity 0.2s ease, transform 0.3s 0.2s ease-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    &.active {
        box-shadow: 2px 0 30px 0 var(--color-orange);
    }
    &.left {
        transition: 0.4s transform ease-out, 0.4s opacity ease-out;
        transform: translateX(-150px);
    }
    &.right {
        transition: 0.4s transform ease-out, 0.4s opacity ease-out;
        transform: translateX(150px);
    }
    &.visible {
        transform: translateX(0);
        opacity: 1;
        pointer-events: all;
    }
}
