.picker__wrapper {
  background-color: var(--color-white);
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datepicker__input {
  border: none;
}

.datepicker__input:focus {
  outline: none;
}

.datepicker__button-text {
  font-size: 16px;
  position: relative;
  display: inline-block;
  max-width: max-content;

  &::after {
    content: '*';
    color: var(--color-orange);
    position: absolute;
    right: -10px;
    top: -2px;
  }
}
