.messageChat {
    display: flex;
    gap: 12px;
    align-items: center;
    position: relative;
    padding-bottom: 8px;

    &:not(:last-child)::before {
        content: "";
        position: absolute;
        width: calc(100% - 90px);
        bottom: 0;
        height: 1px;
        background-color: #f8f8f8;
        right: 0;
    }
}

.link {
    color: currentColor;
    text-decoration: none;
}

.avatar {
    max-width: 61px;
    flex-shrink: 0;
    position: relative;
}

.status {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 3px;
    right: 3px;
    border: 2px solid var(--color-gray-light);
    background-color: var(--color-green);
    border-radius: 50%;
}

.imageAvatar {

    max-width: 61px;
    overflow: hidden;
    width: 100%;
    border-radius: 50%;
    img {
        display: block;
    }
}

.time {
    font-size: 10px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

}

.wrapInfo {
    width: 100%;
    position: relative;


}

.messageInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.countMessage {
    min-width: 20px;
    height: 20px;
    background-color: var(--color-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99999px;
    padding-left: 4px;
    padding-right: 4px;

}

.count {
    font-size: 10px;
}

.lastMessage {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
}
