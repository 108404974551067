.wrapper {
  overflow: hidden;
  height: calc(var(--proxim-app-height) - var(--height-menu-app));
  width: 100%;
  position: absolute;
  z-index: -3;
  opacity: 0;
  left: 0;
  top: 0;
  padding-top: 16px;
  transition: all 0.3s ease;

  &.visibleWrapper {
    opacity: 1;
    z-index: 110;
  }
}

.content {
  height: 100%;
  width: 100%;
  position: relative;
}

.closeIcon {
  position: absolute;
  z-index: 50;
  right: 16px;
  top: 16px;
}

.galleryWrapper {
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.cardFooter {
  position: absolute;
  z-index: 31;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 50px 16px;

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    &:last-of-type {
      margin-bottom: 80px;
    }
  }
}
