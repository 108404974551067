.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    font-size: 17px;
    padding: 15px 25px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    transition: opacity .2s ease;
    font-family: 'SFProDisplay';
    font-weight: 600;
    line-height: 129%;
    &.link {
        padding: 0;
        background-color: transparent;
        border-bottom: 1px solid currentColor;
        border-radius: 0;
        line-height: 1;
    }
}


.small {
    width: auto;
}

.stretched {
    width: 100%;
}

.white {
    background-color: var(--color-white);
    color: var(--color-orange);
}
.orange {
    background-color: var(--color-orange);
    color: var(--color-white);
}

.isLoading {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: .8;
    pointer-events: none;
}

.isDisabled {
    background-color: #C9C8C8;
    pointer-events: none;
    color: var(--color-gray-03);
}

.text-white {
    color: var(--color-white);
}
.text-black {
    color: var(--color-black);
}
.text-orange {
    color: var(--color-orange);
}
.text-gray {
    color: var(--color-gray-03);
}