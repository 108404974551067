.thumb {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #F26A1C;
    outline: none;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: white;
    }

    &.isDragged {
        box-shadow: 0px 0px 10px 1px #F26A1C;
    }
}

.value {
    position: absolute;
    top: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
}