.intro {
    background-color: var(--color-orange);
    background-image: url('../../assets/images/intro-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-width: min(100vw, 84vh);
    height: 100%;
    padding-top: 42px;
}

.intro__text {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 24px;
}

.intro__deckor {
    width: 100%;
    z-index: 1;
}

.intro__deckor__img {
    width: 100%;
    height: 240px;
    object-fit: contain;
}

.intro__content {
    display: flex;
    flex-direction: column;
}

.logoTravelask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 32px;
    color: var(--color-black);
    font-size: 19px;
}

.introFootnote {
    color: rgba(27, 31, 38, 0.72);
    font-size: 12px;
    margin-top: 8px;
}

.labelLogo {
    font-size: 22px;
}

.intro__Headline {
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 24px;
}
