.datepicker {
    position: relative;
}
.react-datepicker {
    border: none;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.1);
}
.react-datepicker__container {
    background-color: var(--color-white);
}
.react-datepicker__input-container {
    input {
        width: 100%;
        border: none;
        font-size: 16px;
        outline: none;
        &::placeholder {
            color: var(--color-gray-placeholder);
            font-size: 16px;
        }
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__icon {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-family: 'SFProText';
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    margin: 0;

    &:hover {
        border-radius: 50%;
    }
    &--selected {
        color: var(--color-orange);
        background-color: var(--color-orange-100);
        &:hover {
            background-color: var(--color-gray-100);
            border-radius: 50%;
        }
    }
}
.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.react-datepicker {
    width: 320px;
    &--picker-active {
        .react-datepicker__month,
        .react-datepicker__day-names {
            display: none;
        }

        .react-datepicker__current-month {
            margin-bottom: 0;
        }
    }
}
.react-datepicker__month {
    margin: 0;
}
.react-datepicker__month-container {
    width: 100%;
    padding: 16px;
}

.react-datepicker-popper .react-datepicker__triangle {
    stroke: var(--color-white);
}
.react-datepicker__header {
    border-bottom: none;
    background-color: var(--color-white);
    padding: 0;
}
.react-datepicker__day-name {
    width: 40px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 138%;
    text-transform: uppercase;
    text-align: center;
    color: var(--color-gray-02);
    font-family: 'SFProText';
    margin: 0;
}

.react-datepicker__header .react-datepicker__current-month {
    font-weight: 600;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.03em;
    padding: 10px;
    padding-left: 16px;
    text-align: left;
    font-family: 'SFProText';
}

.datepicker-footer {
    display: flex;
    justify-content: space-between;
    button {
        font-weight: 400;
        font-size: 17px;
        line-height: 129%;
        letter-spacing: -0.02em;
        border: none;
        background-color: transparent;
        flex-basis: 25%;
        text-align: center;
        padding: 9px 16px 12px;
        color: var(--color-orange);
    }
    .done-button {
        font-weight: 600;
    }
}

.react-datepicker__button {
    background-color: var(--color-white);
    display: flex;
    justify-content: space-between;
    border: none;
    width: 100%;
    padding: 14px 16px;
    border: 1px solid var(--color-white);
    border-radius: 12px;
    &--active {
        border: 1px solid var(--color-orange);
    }
    &--error {
        border: 1px solid var(--color-error);
    }
    margin-bottom: 16px;
}

.react-datepicker__button-text {
    font-size: 16px;
}
.react-datepicker__button-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 16px;
    line-height: 16px;
}
.react-date-picker__calendar--open {
    top: 100% !important;
    bottom: unset !important;
}
